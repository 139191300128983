
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import ContextMenuItem from '@/components/context-menu/item.vue'
import { LandscapeModule } from '@/modules/landscape/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

import { DiagramModule } from '../../store'

@Component({
  components: {
    ContextMenuItem
  },
  name: 'DiagramGroupContextMenuDeleteDiagramGroup'
})
export default class DiagramGroupContextMenuDeleteDiagramGroup extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly diagramGroupId!: string

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get landscapePermission () {
    return this.landscapeModule.landscapePermission(this.currentLandscape)
  }

  get disabledMessage () {
    if (this.currentShareLink) {
      return 'You cannot change share links, please request access from an admin'
    } else if (this.currentVersionId !== 'latest') {
      return 'You cannot change a previously created version'
    } else if (!(this.landscapePermission === 'write' || this.landscapePermission === 'admin')) {
      return 'You don\t have edit access, please request access from an admin'
    }
  }

  deleteDiagramGroup () {
    this.$pushQuery({
      diagram_group_delete_dialog: this.diagramGroupId
    })
  }
}
