
import { TAG_COLOR_ACTIVE } from '@icepanel/app-canvas'
import { DiagramType, ModelObjectType, StatsDetails, StatsPeriods, StatsUser } from '@icepanel/platform-api-client'
import Fuse from 'fuse.js'
import debounce from 'lodash/debounce'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Ref, Watch } from 'vue-property-decorator'
import { RecycleScroller } from 'vue-virtual-scroller'
import { getModule } from 'vuex-module-decorators'

import TableEmptyState from '@/components/table/empty-state.vue'
import TableHeadItem, { ITableHeadItem } from '@/components/table/head-item.vue'
import TableRowItem from '@/components/table/row-item.vue'
import TableSearchInput from '@/components/table/search-input.vue'
import { getDateKeys } from '@/helpers/date-keys'
import { prefixEmoji } from '@/helpers/emojis'
import findNextName from '@/helpers/find-next-name'
import getColor from '@/helpers/get-color'
import { AlertModule } from '@/modules/alert/store'
import ThumbnailMenu from '@/modules/diagram/components/thumbnail-menu.vue'
import { DiagramModule } from '@/modules/diagram/store'
import { DomainModule } from '@/modules/domain/store'
import { EditorModule } from '@/modules/editor/store'
import { FlowModule } from '@/modules/flow/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { OrganizationModule } from '@/modules/organization/store'
import ButtonTabs, { ButtonTab } from '@/modules/overview/components/button-tabs.vue'
import { OverviewModule } from '@/modules/overview/store'
import { ShareModule } from '@/modules/share/store'
import getInitials from '@/modules/user/helpers/get-initials'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../helpers/analytics'
import DiagramContextMenu from './diagram-context-menu/index.vue'
import DiagramGroupContextMenu from './diagram-group-context-menu/index.vue'

export interface IDiagramListItemContributor {
  color: string
  id: string
  initials: string
  name: string
  updatedAt: string
}

export interface IDiagramListItem {
  click: () => void
  contextMenuOpen: (event: PointerEvent) => void
  contributors: IDiagramListItemContributor[]
  diagramCount?: number
  domainName: string
  edits: StatsDetails
  id: string
  index: number
  isExpanded: boolean
  isIndented: boolean
  isLastExpandedItem?: boolean
  keywords: string[]
  lastEdit?: string
  level: number
  name: string
  parentId?: string
  type: DiagramType | 'group'
  views: StatsDetails
}

const DIAGRAM_LEVEL: Record<DiagramType, number> = {
  'app-diagram': 2,
  'component-diagram': 3,
  'context-diagram': 1
}

const MODEL_OBJECT_LEVEL: Record<ModelObjectType, number> = {
  actor: 2,
  app: 3,
  component: 4,
  group: 2,
  root: 1,
  store: 3,
  system: 2
}

@Component({
  components: {
    ButtonTabs,
    DiagramContextMenu,
    DiagramGroupContextMenu,
    RecycleScroller,
    TableEmptyState,
    TableHeadItem,
    TableRowItem,
    TableSearchInput,
    ThumbnailMenu
  },
  name: 'DiagramsTable'
})
export default class DiagramsTable extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  diagramModule = getModule(DiagramModule, this.$store)
  domainModule = getModule(DomainModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  flowModule = getModule(FlowModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  overviewModule = getModule(OverviewModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  searchModel = ''
  creatingContextDiagram = false

  hoverItemId: string | null = null
  hoverItemRef: HTMLElement | null = null

  tabChanged = false
  tabChangedTimer?: number

  contextMenuOpen = false
  contextMenuId: string | null = null
  contextMenuActivatorRef: HTMLElement | null = null

  @Ref() readonly contextMenuRef?: DiagramContextMenu | DiagramGroupContextMenu
  @Ref() readonly contextMenuButtonRefs!: HTMLElement[]

  get sort () {
    return this.$queryValue('sort') ?? 'asc'
  }

  get sortedColumn () {
    return this.$queryValue('sorted_column') ?? 'lastEdit'
  }

  get currentExpandedObjectHandleIds () {
    return this.$queryArray('expanded')
  }

  get search () {
    return this.$queryValue('diagram_search') || ''
  }

  get currentDomainHandleId () {
    return this.$queryValue('domain')
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscapeId () {
    return this.currentVersion?.landscapeId || this.$params.landscapeId
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentDomain () {
    return Object.values(this.domainModule.domains).find(o => o.handleId === this.currentDomainHandleId)
  }

  get domainsAll () {
    return !this.currentDomainHandleId && Object.values(this.domainModule.domains).length > 1
  }

  get domainNamePrefixEmoji () {
    return prefixEmoji(this.currentDomain?.name)
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentLandscape?.organizationId)
  }

  get currentOrganizationPermission () {
    return this.organizationModule.organizationPermission(this.currentOrganization)
  }

  get currentOrganizationLimits () {
    return this.organizationModule.organizationLimits(this.currentOrganization)
  }

  get hoverItem () {
    return this.hoverItemId ? this.items[this.hoverItemId] : undefined
  }

  get selectedTimeline () {
    return this.$queryValue('timeline') ?? localStorage.getItem('diagramsSelectedTimelineTab') ?? 'week'
  }

  get modelObjectRoot () {
    return Object.values(this.modelModule.objects).find(o => o.type === 'root' && (!this.currentDomain || o.domainId === this.currentDomain.id))
  }

  get authorizedToEdit () {
    return this.currentOrganizationPermission === 'admin' || this.currentOrganizationPermission === 'write'
  }

  get actionsEnabled () {
    return !this.currentShareLink && this.currentVersion?.tags.includes('latest')
  }

  get contextMenuDiagram () {
    return this.contextMenuId ? this.diagramModule.diagrams[this.contextMenuId] : undefined
  }

  get contextMenuDiagramGroup () {
    return this.contextMenuId ? this.diagramModule.diagramGroups[this.contextMenuId] : undefined
  }

  get loading () {
    return !this.currentShareLink && (
      this.versionModule.travelling ||
      !this.landscapeModule.landscapesListStatus.success ||
      !this.organizationModule.organizationsListStatus.success ||
      (this.landscapeModule.landscapeSubscriptionStatus.successInfo.landscapeId !== this.currentLandscapeId && !this.landscapeModule.landscapeSubscriptionStatus.loadingInfo.reconnect) ||
      (this.domainModule.domainsSubscriptionStatus.successInfo.landscapeId !== this.currentLandscapeId && !this.domainModule.domainsSubscriptionStatus.loadingInfo.reconnect) ||
      (this.diagramModule.diagramsSubscriptionStatus.successInfo.landscapeId !== this.currentLandscapeId && !this.diagramModule.diagramsSubscriptionStatus.loadingInfo.reconnect) ||
      (this.diagramModule.diagramGroupsSubscriptionStatus.successInfo.landscapeId !== this.currentLandscapeId && !this.diagramModule.diagramGroupsSubscriptionStatus.loadingInfo.reconnect) ||
      (this.modelModule.objectsSubscriptionStatus.successInfo.landscapeId !== this.currentLandscapeId && !this.modelModule.objectsSubscriptionStatus.loadingInfo.reconnect)
    )
  }

  get progressBarEnabled () {
    return this.searchModel !== this.search || this.tabChanged
  }

  get tableHeadItems (): ITableHeadItem[] {
    return [
      { id: 'name', title: 'Diagram name', width: '20%' },
      { id: 'domain', title: 'Domain', width: '20%' },
      { id: 'level', title: 'C4 level', width: '10%' },
      { id: 'contributors', title: 'Contributors', width: '12%' },
      { id: 'lastEdit', title: 'Last edit', width: '12%' },
      { id: 'views', title: 'Views', width: '10%' },
      { id: 'edits', title: 'Edits', width: '10%' },
      { id: 'actions', sortable: false, title: '', width: '6%' }
    ]
  }

  get timelineTabs (): ButtonTab[] {
    return this.overviewModule.timelineTabs
  }

  get diagrams () {
    return Object
      .values(this.diagramModule.diagrams)
      .filter(o => {
        const model = this.modelModule.objects[o.modelId]
        if (!model || (this.currentDomain && model.domainId !== this.currentDomain.id)) {
          return false
        } else if (o.status !== 'current') {
          return false
        } else {
          return true
        }
      })
  }

  get diagramGroups () {
    return Object
      .values(this.diagramModule.diagramGroups)
      .filter(o => {
        const model = this.modelModule.objects[o.modelId]
        if (!model || (this.currentDomain && model.domainId !== this.currentDomain.id)) {
          return false
        } else {
          return true
        }
      })
  }

  get diagramItems () {
    return this.diagrams.map((o): IDiagramListItem => {
      const diagramModelObject = this.modelModule.objects[o.modelId]
      const diagramDomain = this.domainModule.domains[diagramModelObject.domainId]
      const diagramGroup = o.groupId ? this.diagramModule.diagramGroups[o.groupId] : undefined

      const editStats = this.countStats(o.stats.edits)
      const viewStats = this.countStats(o.stats.views)

      const contributors = Object
        .values(editStats.users)
        .sort((a, b) => b.updatedAt.localeCompare(a.updatedAt))
        .map(o => ({
          color: TAG_COLOR_ACTIVE[getColor(o.id)],
          id: o.id,
          initials: o.name ? getInitials(o.name) : '',
          name: o.name ?? '',
          updatedAt: o.updatedAt
        }))
        .filter(o => o && o.name)

      return {
        click: () => this.$router.push({
          name: this.currentShareLink ? 'share-diagram' : this.currentVersionId === 'latest' ? 'editor-diagram' : 'version-diagram',
          params: {
            landscapeId: this.currentLandscapeId || ' ',
            versionId: this.currentVersionId
          },
          query: {
            diagram: o.id,
            model: o.modelId
          }
        }),
        contextMenuOpen: (event: PointerEvent) => {
          const contextMenuOpen = this.contextMenuId === o.id ? !this.contextMenuOpen : true
          setTimeout(() => {
            this.contextMenuOpen = contextMenuOpen
            this.contextMenuId = o.id
            this.contextMenuActivatorRef = event.target as HTMLElement | null
          })
        },
        contributors,
        domainName: diagramDomain?.name || 'Domain',
        edits: editStats,
        id: o.id,
        index: o.index,
        isExpanded: false,
        isIndented: !!diagramGroup,
        keywords: [
          o.name,
          diagramDomain?.name || 'Domain'
        ],
        lastEdit: contributors[0]?.updatedAt,
        level: DIAGRAM_LEVEL[o.type] ?? 0,
        name: o.name,
        parentId: diagramGroup?.id,
        type: o.type,
        views: viewStats
      }
    })
  }

  get diagramGroupItems () {
    return this.diagramGroups.map((o): IDiagramListItem => {
      const diagramModelObject = this.modelModule.objects[o.modelId]
      const diagramDomain = this.domainModule.domains[diagramModelObject.domainId]

      const diagrams = this.diagramItems.filter(d => d.parentId === o.id)

      const edits = diagrams.reduce((p, c) => ({
        count: c.edits.count + p.count,
        users: {
          ...p.users,
          ...c.edits.users
        }
      }), {
        count: 0,
        users: {}
      } as StatsDetails)

      const views = diagrams.reduce((p, c) => ({
        count: c.views.count + p.count,
        users: {
          ...p.users,
          ...c.views.users
        }
      }), {
        count: 0,
        users: {}
      } as StatsDetails)

      const contributors = Object
        .values(edits.users)
        .sort((a, b) => b.updatedAt.localeCompare(a.updatedAt))
        .map(o => ({
          color: TAG_COLOR_ACTIVE[getColor(o.id)],
          id: o.id,
          initials: o.name ? getInitials(o.name) : '',
          name: o.name ?? '',
          updatedAt: o.updatedAt
        }))
        .filter(o => o && o.name)

      return {
        click: () => {
          if (diagrams.length) {
            this.toggleGroup(o.handleId)
          }
        },
        contextMenuOpen: (event: PointerEvent) => {
          const contextMenuOpen = this.contextMenuId === o.id ? !this.contextMenuOpen : true
          setTimeout(() => {
            this.contextMenuOpen = contextMenuOpen
            this.contextMenuId = o.id
            this.contextMenuActivatorRef = event.target as HTMLElement | null
          })
        },
        contributors,
        diagramCount: diagrams.length,
        domainName: diagramDomain?.name || 'Domain',
        edits,
        id: o.id,
        index: o.index,
        isExpanded: this.currentExpandedObjectHandleIds.includes(o.handleId),
        isIndented: false,
        isLastExpandedItem: false,
        keywords: [
          o.name,
          diagramDomain?.name || 'Domain',
          ...diagrams.flatMap(o => o.keywords)
        ],
        lastEdit: contributors[0]?.updatedAt,
        level: MODEL_OBJECT_LEVEL[diagramModelObject.type] ?? 0,
        name: o.name,
        type: 'group',
        views
      }
    })
  }

  get items () {
    return this.loading ? [] : [...this.diagramGroupItems, ...this.diagramItems]
  }

  get sortedItems () {
    return this.items.sort((a, b) => {
      let sortValueA: any
      let sortValueB: any

      if (this.sortedColumn === 'level') {
        sortValueA = a.level
        sortValueB = b.level
      } else if (this.sortedColumn === 'name') {
        sortValueA = a.name
        sortValueB = b.name
      } else if (this.sortedColumn === 'domain') {
        sortValueA = a.domainName
        sortValueB = b.domainName
      } else if (this.sortedColumn === 'lastEdit') {
        sortValueA = b.lastEdit ?? ''
        sortValueB = a.lastEdit ?? ''
      } else if (this.sortedColumn === 'contributors') {
        sortValueA = a.contributors.length
        sortValueB = b.contributors.length
      } else if (this.sortedColumn === 'views') {
        sortValueA = a.views?.count ?? 0
        sortValueB = b.views?.count ?? 0
      } else if (this.sortedColumn === 'edits') {
        sortValueA = a.edits?.count ?? 0
        sortValueB = b.edits?.count ?? 0
      } else {
        sortValueA = a.index
        sortValueB = b.index
      }

      if (sortValueA === sortValueB) {
        return 0
      } else if (sortValueA > sortValueB) {
        return this.sort === 'asc' ? 1 : -1
      } else {
        return this.sort === 'asc' ? -1 : 1
      }
    })
  }

  get filteredItemsFuzzy () {
    return new Fuse(this.sortedItems, {
      keys: ['keywords'],
      threshold: 0.3
    })
  }

  get filteredItems () {
    return this.search ? this.filteredItemsFuzzy.search(this.search).map(o => o.item) : this.sortedItems
  }

  get groupedItems () {
    const sortedItems = this.sortedItems
    const filteredItems = this.filteredItems
    return filteredItems.flatMap(o => {
      if (o.parentId) {
        return []
      } else if (o.type === 'group') {
        let childItems: IDiagramListItem[] = []
        if (o.isExpanded) {
          childItems = sortedItems.filter(i => i.parentId === o.id)
        } else if (this.search) {
          childItems = filteredItems.filter(i => i.parentId === o.id)
          if (childItems.length) {
            o.isExpanded = true
            o.click = () => {}
          }
        }
        return [
          o,
          ...childItems.map((v, i, a) => i === a.length - 1 ? { ...v, isLastExpandedItem: true } : v)
        ]
      } else {
        return [o]
      }
    })
  }

  @Watch('groupedItems')
  async onGroupedItemsChanged () {
    if (this.contextMenuId) {
      await this.$nextTick()
      this.contextMenuActivatorRef = this.contextMenuButtonRefs.find(o => o.id === `context-menu-button-${this.contextMenuId}`) ?? null
      await this.$nextTick()
      this.contextMenuRef?.menuRef.updateDimensions()
    }
  }

  @Watch('contextMenuId')
  async onContextMenuIdChanged () {
    if (this.contextMenuId) {
      await this.$nextTick()
      this.contextMenuRef?.menuRef.updateDimensions()
    }
  }

  @Watch('search')
  onSearchChange (newVal: string) {
    if (newVal !== this.searchModel) {
      this.searchModel = newVal
    }
  }

  mounted () {
    if (this.search) {
      this.searchModel = this.search
    }
  }

  destroyed () {
    clearTimeout(this.tabChangedTimer)
  }

  selectTimeline (timeline: string) {
    this.tabChanged = true
    clearTimeout(this.tabChangedTimer)
    this.tabChangedTimer = window.setTimeout(() => {
      this.tabChanged = false
    }, 600)

    this.$replaceQuery({
      timeline
    })

    localStorage.setItem('diagramsSelectedTimelineTab', timeline)
  }

  updateQueryDebounce = debounce(this.updateQuery.bind(this), 500, {
    leading: false,
    trailing: true
  })

  async updateQuery () {
    const query: any = {}

    if (this.search !== this.searchModel) {
      query.diagram_search = this.searchModel || undefined
    }

    await this.$replaceQuery(query)
  }

  async sortColumn (id: string) {
    const query: any = {}

    if (this.sortedColumn === id) {
      query.sort = this.sort === 'asc' ? 'desc' : this.sort === 'desc' ? undefined : 'asc'
    } else {
      query.sort = 'asc'
    }

    if (query.sort) {
      query.sorted_column = id
    } else {
      query.sorted_column = undefined
    }

    await this.$replaceQuery(query)
  }

  getLastUser (users: Record<string, StatsUser>) {
    return Object.values(users).reduce((p, c) => !p || c.updatedAt > p.updatedAt ? c : p, null as StatsUser | null)
  }

  countStats (periods: StatsPeriods): StatsDetails {
    const { monthStartAtKey, todayStartAtKey, weekStartAtKey, yesterdayStartAtKey } = getDateKeys()

    const statsDefault: StatsDetails = { count: 0, users: {} }

    switch (this.selectedTimeline) {
      case 'today':
        return periods.day[todayStartAtKey] ?? statsDefault
      case 'yesterday':
        return periods.day[yesterdayStartAtKey] ?? statsDefault
      case 'week':
        return periods.week[weekStartAtKey] ?? statsDefault
      case 'month':
        return periods.month[monthStartAtKey] ?? statsDefault
      default:
        return periods.all
    }
  }

  async toggleGroup (handleId: string) {
    if (this.currentExpandedObjectHandleIds.includes(handleId)) {
      this.hoverItemId = null
      this.hoverItemRef = null

      await this.$replaceQuery({
        expanded: this.$removeQueryArray(handleId)
      })
    } else {
      await this.$replaceQuery({
        expanded: this.$unionQueryArray(handleId)
      })
    }
  }

  async createContextDiagram () {
    const contextItems = this.items.filter(o => o.type === 'context-diagram')
    const currentLandscape = this.currentLandscape
    const currentVersion = this.currentVersion
    if (!currentLandscape || !currentVersion || !contextItems || !this.modelObjectRoot) {
      return
    }

    this.creatingContextDiagram = true

    const nextIndex = contextItems.reduce((p, c) => c.index > p ? c.index : p, -1) + 1

    const contextDiagrams = this.diagrams.filter(o => o.type === 'context-diagram')
    const { diagram, diagramUpsert } = this.diagramModule.generateDiagram(currentLandscape.id, currentVersion.id, {
      groupId: null,
      index: nextIndex,
      modelId: this.modelObjectRoot.id,
      name: findNextName('Context Diagram', contextDiagrams),
      type: 'context-diagram'
    })
    const { diagramContentUpsert } = this.diagramModule.generateDiagramContent(currentLandscape.id, currentVersion.id, diagram, {})

    await this.diagramModule.diagramUpsert({
      diagramId: diagram.id,
      landscapeId: currentLandscape.id,
      props: {
        ...diagramUpsert,
        ...diagramContentUpsert
      },
      updateViewedAt: true,
      versionId: currentVersion.id
    })

    analytics.diagramCreate.track(this, {
      diagramType: diagram.type,
      landscapeId: [currentLandscape.id],
      organizationId: [currentLandscape.organizationId]
    })

    this.$router.push({
      name: this.currentShareLink ? 'share-diagram' : this.currentVersionId === 'latest' ? 'editor-diagram' : 'version-diagram',
      params: {
        landscapeId: this.currentLandscapeId || ' ',
        versionId: this.currentVersionId
      },
      query: {
        diagram: diagram.handleId,
        model: this.modelObjectRoot.handleId
      }
    })

    this.creatingContextDiagram = false
  }
}
