
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'

import ContextMenuContent from '@/components/context-menu/content.vue'
import ContextMenu from '@/components/context-menu/index.vue'

import DeleteDiagram from './delete-diagram-group.vue'

@Component({
  components: {
    ContextMenu,
    ContextMenuContent,
    DeleteDiagram
  },
  name: 'DiagramGroupContextMenu'
})
export default class DiagramGroupContextMenu extends Vue {
  @Prop() readonly diagramGroupId!: string
  @Prop() readonly menuProps?: any
  @Prop() readonly value?: any

  @Ref() readonly menuRef!: ContextMenu
}
